/* eslint-disable */
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect } from 'react'

import DATA from './constData.json'
import CountDown from './CountDown'
import OTPFormInput from './input'
import { useTheme } from '@mui/material/styles'

const SUCCESS = 'success'
const PENDING = 'pending'
const ERROR = 'error'
const IDLE = 'idle'

let resend
let reset

const InputOTP = (props) => {
  const {
    id,
    endpoints,
    data,
    labels,

    resendTimeoutFormat,
    resendTimeout,

    onSuccess,
    onError,
    onResend,
    onChange,
    onMaxRetries,

    sx,
    skipCallback,
    onboadingPhoneOtpAbTest,
  } = props
  const [otp, setOtp] = useState()
  const [state, setState] = useState({ result: IDLE })
  const [isError, setIsError] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [resendEnabled, setResendEnabled] = useState(false)
  const [countdownActive, setCountdownActive] = useState(true)
  const materialTheme = useTheme()
  const isCaseA = onboadingPhoneOtpAbTest === 'case_a'

  const isValidCode = (value) => /^\d{6}$/.test(value)

  const inputStyle = {
    textAlign: 'center',
    width: '100%',
  }

  const containerStyle = {
    display: 'flex',
  }

  const checkCode = async (val) => {
    if (!isValidCode(val)) {
      return
    }

    setState({ result: PENDING })
    axios
      .post(endpoints.validate, {
        code: val,
        nonce: data.nonce,
      })
      .then((e) => {
        setState({
          result: SUCCESS,
          status: e.status,
        })
      })
      .catch((e) => {
        setState({
          result: ERROR,
          status: e?.response?.data,
          via: 'validate',
        })

        if ([1000, 1003].includes(e?.response?.data?.code)) {
          onMaxRetries()
        }
      })
  }

  useEffect(() => {
    if (state.result === SUCCESS) {
      setIsError(false)

      if (!state.status) return

      onSuccess(state)
    } else if (state.result === PENDING) {
      setIsDisabled(true)
    } else if (state.result === ERROR) {
      setOtp()
      setIsDisabled(false)
      setIsError(true)

      onError(state)
    }
  }, [state])

  const clearError = () => {
    setIsError(false)
    setState({ result: IDLE })
  }

  const handleChange = (val) => {
    setOtp(val)

    switch (val.length) {
      case 6:
        checkCode(val)
        break
      default:
        clearError()
        break
    }

    if (typeof onChange === 'function') {
      onChange(val)
    }
  }

  const handlePaste = (val) => {
    const value = val.clipboardData.getData('Text')

    if (isValidCode(value)) {
      setOtp(value)
    }
  }

  reset = () => {
    setOtp()
    setIsError(false)
    setResendEnabled(false)
    setCountdownActive(true)
  }

  resend = () => {
    reset()

    axios
      .post(endpoints.resend, {
        dm: data.delivery_mechanism,
        nonce: data.nonce,
      })
      .then(() => {
        clearError()
        if (typeof onResend === 'function') {
          onResend()
        }
      })
      .catch((e) => {
        setState({
          result: ERROR,
          status: e?.response?.data,
          via: 'resend',
        })
      })
  }

  const renderLabels = () => {
    const RenderTimeout = () => (
      <Typography variant="Labels.Small">
        {labels.resendCountdownLabel}&nbsp;
        <strong>
          {countdownActive ? (
            <CountDown
              format={resendTimeoutFormat}
              onComplete={() => {
                setCountdownActive(false)
                setResendEnabled(true)
              }}
              timeout={resendTimeout || DATA.resendTimeout}
            />
          ) : null}
        </strong>
      </Typography>
    )

    const RenderResendBtn = () => (
      <Button
        className="otp-resend"
        disabled={state.result === SUCCESS || state.result === PENDING}
        onClick={resend}
        variant="text"
        size="small"
        sx={{ textTransform: 'Capitalize' }}
      >
        {labels.resendTxt}
      </Button>
    )

    const RenderSkipBtn = () => (
      <Button
        className="otp-skip"
        onClick={skipCallback}
        variant="contained"
        size="small"
        sx={{
          textTransform: 'Capitalize',
          width: '100%',
          height: '48px',
          marginTop: '22px',
          backgroundColor: materialTheme.backgrounds[0],
          color: materialTheme.palette.text.primary,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: materialTheme.backgrounds[0],
            boxShadow: 'none',
          },
        }}
      >
        {labels.skipTxt}
      </Button>
    )

    const RenderLoading = () => (
      <Stack justifyContent="center" direction="row" spacing={2}>
        <CircularProgress size={16} />
        <Typography variant="Labels.Small">{labels.pendingTxt}</Typography>
      </Stack>
    )

    const showCountDown =
      !resendEnabled && (state.result === IDLE || state.result === ERROR)

    return (
      <Box sx={{ textAlign: resendEnabled ? 'center' : null }}>
        <Box
          sx={{
            position: showCountDown ? null : 'absolute',
            visibility: showCountDown ? 'visible' : 'hidden',
          }}
        >
          {RenderTimeout()}
        </Box>
        {resendEnabled && state.result !== PENDING ? RenderResendBtn() : null}
        {state.result === PENDING ? RenderLoading() : null}
        <Box>{isCaseA ? RenderSkipBtn() : null}</Box>
      </Box>
    )
  }

  const theme = {
    tokens: {
      'Sys.Semantic.Error.Base': '#e51c01',
      'Sys.Surface.On Surface': '#25282a',
      'Sys.Primary.Base': '#fc4c02',
      'Sys.Semantic.Neutral.Base': '#f3f4f6',
    },
  }

  const styles = useMemo(
    () => () => ({
      input: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          appearance: 'none',
          margin: 0,
        },
        '&:focus': {
          border: `1px solid ${
            isError
              ? theme.tokens['Sys.Semantic.Error.Base']
              : theme.tokens['Sys.Surface.On Surface']
          } !important`,
          color: theme.tokens['Sys.Primary.Base'],
          outline: `none`,
        },
        border: `1px solid ${
          isError
            ? theme.tokens['Sys.Semantic.Error.Base']
            : theme.tokens['Sys.Semantic.Neutral.Base']
        }`,
        borderRadius: '8px',
        color: isError
          ? theme.tokens['Sys.Semantic.Error.Base']
          : theme.tokens['Sys.Surface.On Surface'],
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: 40 / 28,
        maxHeight: '60px',
        maxWidth: '58px',
        marginRight: '8px',
        padding: '12px',
      },
      width: '396px',
      ...sx,
    }),
    [isError, sx]
  )

  return (
    <FormControl id={id} sx={styles}>
      <OTPFormInput
        containerStyle={containerStyle}
        disabled={isDisabled}
        inputStyle={inputStyle}
        onChangeInternal={handleChange}
        onPaste={handlePaste}
        otp={otp}
        value={otp}
        {...props}
      />
      <FormHelperText
        error
        id={`otp-error-label${id}`}
        sx={{ ml: 0, visibility: isError ? 'visible' : 'hidden' }}
      >
        {labels.errorLabel}
      </FormHelperText>
      <FormHelperText id={`otp-labels-${id}`} component="div" sx={{ ml: 0 }}>
        {renderLabels()}
      </FormHelperText>
    </FormControl>
  )
}

export { InputOTP as default, resend, reset }

InputOTP.propTypes = {
  data: PropTypes.shape().isRequired,
  endpoints: PropTypes.shape().isRequired,
  id: PropTypes.string,
  labels: PropTypes.shape().isRequired,

  onChange: PropTypes.func,
  onError: PropTypes.func.isRequired,

  onMaxRetries: PropTypes.func,
  onResend: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  resendTimeout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resendTimeoutFormat: PropTypes.string,

  sx: PropTypes.shape(),
}

InputOTP.defaultProps = {
  id: Math.random()
    .toString(36)
    .replace(/[^a-z0-9]+/g, '')
    .substr(0, 8),
  onChange: null,
  onMaxRetries: null,
  onResend: null,
  resendTimeout: 15000,
  resendTimeoutFormat: '00:00:00',

  sx: null,
}
