import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import {
  ROUTE_BUSINESS_ADDRESS_CHOOSE,
  ENV,
  ROUTE_API_BUSINESS_ACTIVITY,
} from 'constants/routes'
import { useBusinessFormContext, Actions } from 'components/Form/store/business'
import { useUserFormContext } from 'components/Form/store/user'
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import FormText from 'components/Form/Text'
import SubmitError from 'components/SubmitError'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import DATA from 'constants/pages/businessInfo.json'
import SITE from 'constants/site.json'
import { makeStyles } from '@mui/styles'
import { updateInfo } from 'helpers/fetchUtils'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const useStyles = makeStyles((theme) => ({
  businessName: {
    '& input': {
      height: 20,
      marginTop: 18,
      padding: `${theme.space[2]}px ${theme.space[4]}px`,
    },
    '& label.Mui-focused': {
      top: 18,
    },
    '& label.MuiInputLabel-shrink': {
      top: 18,
    },
    height: 56,
  },
}))

const BusinessInfoPage = () => {
  const navigate = useNavigate()
  const businessNameId = DATA.FORMIKS_ID.BUSINESS_NAME_ID
  const businessUrl = DATA.FORMIKS_ID.BUSINESS_URL_ID
  const { businessDispatch, businessState: businessInfo } =
    useBusinessFormContext()
  const { device } = useGlobalContext()
  const classes = useStyles()
  const { userState } = useUserFormContext()
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isBusinessURL, setIsBusinessURL] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })

    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const BUSINESS_INFO_VALIDATION = yup.object().shape({
    [businessNameId]: yup
      .string()
      .test('test-not-empty', DATA.emptyError, (value) => !!value)
      .min(5, 'El nombre debe tener al menos 5 caracteres')
      .max(45, 'El nombre debe tener menos de 45 caracteres')
      .matches(/^[\w\s]+$/, 'Solo se permiten letras y números'),
    [businessUrl]: yup
      .string()
      .test(
        'test-not-empty',
        'Ingresa el link de tu tienda en línea.',
        (value) => (isBusinessURL ? !!value : true)
      )
      .test('test-url', 'Por favor, ingresa una URL válida.', (value) => {
        if (isBusinessURL) {
          const urlPattern =
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/

          return urlPattern.test(value)
        }

        return true
      }),
  })

  const formik = useFormik({
    initialValues: {
      [businessNameId]: '',
      [businessUrl]: '',
    },
    onSubmit: (values) => {
      setLoading(true)

      businessDispatch({
        business_info: {
          ...businessInfo?.business_info,
          name: values[businessNameId],
        },
        type: Actions.UPDATE_BUSINESS_INFO,
      })

      updateInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_BUSINESS_ACTIVITY}`, {
        industry_id: businessInfo?.business_info?.businessId,
        name: values[businessNameId],
      })
        .then(() => {
          segment.track(DATA.SEGMENT.EVENTS.ADD_BUSINESS_NAME)
          if (isBusinessURL) {
            segment.track(DATA.SEGMENT.EVENTS.ADD_BUSINESS_URL, {
              url: values[businessUrl],
            })
          }
          segment.identify(
            userState?.mid ?? localStorage.getItem(SITE.LS_MID),
            {
              business_name: values[businessNameId],
            }
          )
          navigate(ROUTE_BUSINESS_ADDRESS_CHOOSE)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setIsSubmitError(true)
          setOpenSnackbar(true)

          cleverTap.event(DATA.CLEVERTAP.EVENTS, {
            ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
            ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE,
            OS: device.type,
          })
        })
    },
    validationSchema: BUSINESS_INFO_VALIDATION,
  })

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleSubmit = () => {
    if (formik.errors[businessNameId]) {
      segment.track(DATA.SEGMENT.EVENTS.ADD_BUSINESS_NAME_ERROR)
    }
    if (formik.errors[businessUrl]) {
      segment.track(DATA.SEGMENT.EVENTS.ADD_BUSINESS_URL_ERROR)
    }

    formik.handleSubmit()
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        loading={loading}
        onContinue={handleSubmit}
      >
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <FormText
              className={classes.businessName}
              error={
                formik.touched[businessNameId] && formik.errors[businessNameId]
              }
              id={businessNameId}
              label={DATA.label}
              onChange={formik.handleChange}
              value={formik.values[businessNameId]}
            />
            <small>{DATA.content.smallText}</small>

            <FormControlLabel
              checked={isBusinessURL}
              control={
                <Checkbox data-testid="bussinesinfo-checkbox-onlinestore" />
              }
              label={
                <Typography
                  data-testid="bussinesinfo-label-onlinestore"
                  variant="body1"
                >
                  {DATA.hasOnlineStore}
                </Typography>
              }
              onChange={() => {
                setIsBusinessURL(!isBusinessURL)
              }}
              sx={{ ml: 0 }}
              value={isBusinessURL}
            />
            {isBusinessURL ? (
              <Stack spacing={2}>
                <FormText
                  error={
                    formik.touched[businessUrl] && formik.errors[businessUrl]
                  }
                  id={businessUrl}
                  label={DATA.labelOnlineStore}
                  onChange={formik.handleChange}
                  type="url"
                  value={formik.values[businessUrl]}
                />
                <small>{DATA.onlineStoreHelperTxt}</small>
              </Stack>
            ) : null}
          </Stack>
        </form>
        <SubmitError
          msg={!isSubmitError && DATA.errorLabel}
          onClose={handleCloseSnackbar}
          open={openSnackbar}
        />
      </StepWrapper>
    </Layout>
  )
}

export default BusinessInfoPage
