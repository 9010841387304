import { useEffect, useState } from 'react'
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { actions, useUserFormContext } from 'components/Form/store/user'
import {
  getEmailDebug,
  getLoginLink,
  isDevice,
  isWebView,
  validateEmail,
} from 'utils'
import { postInfo } from 'helpers/fetchUtils'
import Button from 'components/Button'
import FormText from 'components/Form/Text'
import Layout from 'components/Layout'
import LoadingButton from 'components/LoadingButton'
import StepWrapper from 'components/StepWrapper'
import DATA from 'constants/pages/account.json'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import {
  ENV,
  ROUTE_API_CHECKMAIL,
  ROUTE_API_SIGNUP,
  ROUTE_VALIDATION,
  ROUTE_PROFILE_SETUP,
  ROUTE_HOME,
} from 'constants/routes'
import DIALOGS from 'constants/dialogs.json'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGlobalContext } from 'store/global'
import Tac from 'components/Tac'
import FormPassword from 'components/Form/Password'
import { MiddleDot, TickFail, TickSuccess } from 'components/Icons'
import { RULES } from 'utils/password-requisites'
import { checkForbidden, checkUsername, isForbidden } from 'helpers/account'
import {
  // backMobileApp,
  startNativeBridge,
} from 'helpers/callNativeBridge'
import SubmitError from 'components/SubmitError'
import Cookies from 'js-cookie'
import { getToken } from 'helpers/tokenHelpers'
import { setAppsFlyerUrl } from 'helpers/appsflyer'
import { setQueryParamsStringInSessionStorage } from 'helpers/params'

const AccountPage = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [params] = useSearchParams()
  const { userDispatch, userDispatch: formDispatch } = useUserFormContext()
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [accountExists, setAccountExists] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [tacAccepted, setTacAccepted] = useState(false)

  const [validSteps, setValidSteps] = useState(
    Array.from({ length: RULES.length }, () => false)
  )
  const navigate = useNavigate()

  const OBAssistedEmail =
    params.get('assisted_email') || sessionStorage.getItem('OBAssistedEmail')
  const OBAssistedOppId =
    params.get('opportunity') || sessionStorage.getItem('OBAssistedOppId')
  const emailId = DATA.FORMIKS_ID.EMAIL
  const passwordId = DATA.FORMIKS_ID.PASSWORD
  const tacId = DATA.FORMIKS_ID.TAC
  const isWv = isWebView()

  const appVersion = Cookies.get('app_version')

  useEffect(() => {
    const appsFlyerUrl = params.get('af_url')

    // eslint-disable-next-line no-console
    console.log('appsFlyerUrl', appsFlyerUrl)

    if (appVersion) {
      segment.track(DATA.SEGMENT.EVENTS.TRACK_VERSION, {
        app_version: appVersion,
      })
    }

    if (getToken()) {
      navigate(ROUTE_PROFILE_SETUP)
    }

    if (appsFlyerUrl) {
      setAppsFlyerUrl(appsFlyerUrl)
    }

    const newParams = new URLSearchParams(params)

    newParams.delete('af_url')

    if (newParams.toString())
      setQueryParamsStringInSessionStorage(newParams.toString())
  }, [])

  useEffect(() => {
    if (OBAssistedEmail) {
      sessionStorage.setItem('OBAssistedEmail', OBAssistedEmail)
    }

    if (OBAssistedOppId) {
      sessionStorage.setItem('OBAssistedOppId', OBAssistedOppId)
    }
  }, [OBAssistedEmail, OBAssistedOppId])

  const checkValidity = (value) => {
    const steps = []
    let isInvalid = false

    RULES.forEach((req) => {
      const reqSatified = value?.match(req.match)

      if (!reqSatified) {
        isInvalid = true
      }

      steps.push(!!reqSatified)
    })

    setValidSteps(steps)

    return !isInvalid
  }

  const handleClickLogin = (event) => {
    if (isWv) {
      event.preventDefault()
      segment.track(DATA.SEGMENT.EVENTS.GO_TO_LOGIN)
      startNativeBridge(event, { to: 'login' })
    }
  }

  const FORM_VALIDATION = yup.object().shape({
    [emailId]: yup
      .string()
      .test('test-not-empty', DATA.ERROR_MESSAGE.MAIL.EMPTY, (val) => !!val)
      .test(
        'test-email-valid',
        DATA.ERROR_MESSAGE.MAIL.INVALID,
        (val) => val && validateEmail(getEmailDebug(val))
      )
      .test('test-account-exists', null, () => !accountExists),
    [passwordId]: yup
      .string()
      .test(
        'test-non-empty',
        <Box sx={{ display: 'none' }}>
          {DATA.ERROR_MESSAGE.PASSWORD.errorLabel}
        </Box>,
        (value) => !!value
      )
      .test(
        'test-validity',
        <Box sx={{ display: 'none' }}>
          {DATA.ERROR_MESSAGE.PASSWORD.errorLabel}
        </Box>,
        (value) => checkValidity(value)
      )
      .test(
        'test-forbidden-text',
        (input) =>
          `${DATA.ERROR_MESSAGE.PASSWORD.errorLabel2} ${isForbidden(
            input.value
          )}`,
        (value) => checkForbidden(value, device)
      )
      // .test('test-3-repeat', DATA.ERROR_MESSAGE.PASSWORD.errorLabel3, (value) =>
      //   checkValidThree(value, device)
      // )
      // .test(
      //   'test-3-consecutive',
      //   DATA.ERROR_MESSAGE.PASSWORD.errorLabel4,
      //   (value) => checkConsecutive(value, device)
      // )
      .test(
        'forbid-username',
        DATA.ERROR_MESSAGE.PASSWORD.errorLabel5,
        (value) =>
          checkUsername(
            document.querySelector(`[name=${emailId}]`).value,
            value,
            device
          )
      ),
    [tacId]: yup.string().test('test-tac-accepted', '', () => tacAccepted),
  })

  const formik = useFormik({
    initialValues: {
      [emailId]: OBAssistedEmail || '',
      [passwordId]: '',
      [tacId]: tacAccepted,
    },
    onSubmit: (values) => {
      setLoading(true)
      formDispatch({ password: values[emailId], type: actions.UPDATE_EMAIL })
      formDispatch({
        password: values[passwordId],
        type: actions.UPDATE_PASSWORD,
      })
      segment.track(DATA.SEGMENT.EVENTS.TAC_ACCEPTED)

      postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_CHECKMAIL}`, {
        email: values[emailId],
      })
        .then(({ data }) => {
          userDispatch({ email: values[emailId], type: actions.UPDATE_EMAIL })

          if (data.isEmailAvailable) {
            cleverTap.event(DATA.CLEVERTAP.EVENTS.SIGNUP, {
              ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
              OS: device.type,
              Target: DATA.CLEVERTAP.TARGET.CONTINUE,
            })

            segment.track(DATA.SEGMENT.EVENTS.VALID_CREDENTIALS)

            postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_SIGNUP}`, {
              ...DATA.SIGNUP,
              email: values[emailId],
              password: values[passwordId],
            })
              .then(() => {
                segment.track(DATA.SEGMENT.EVENTS.CREATE_PASSWORD)
                setLoading(false)
                navigate(ROUTE_VALIDATION)
              })
              .catch(() => {
                setOpenSnackbar(true)
              })
          } else if (!data.isActiveMerchant) {
            setAccountExists(true)

            segment.track(DATA.SEGMENT.EVENTS.EXISTING_CREDENTIALS)

            cleverTap.event(DATA.CLEVERTAP.EVENTS.SIGNUP, {
              ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
              OS: device.type,
              Target: DATA.CLEVERTAP.TARGET.ALREADY_HAVE_ACCOUNT,
            })
          } else {
            setAccountExists(true)

            cleverTap.event(DATA.CLEVERTAP.EVENTS.SIGNUP, {
              ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
              ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE_ACCOUNT_EXISTS,
              OS: device.type,
            })
          }
        })
        .catch(() => {
          cleverTap.event(DATA.CLEVERTAP.EVENTS.SIGNUP, {
            ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
            ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE_OTHER,
            OS: device.type,
          })

          setOpenSnackbar(true)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    validationSchema: FORM_VALIDATION,
  })

  const handleClickLinkExternal = (e) => {
    e.preventDefault()

    cleverTap.event(DATA.CLEVERTAP.EVENTS.WELCOME, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.CREATE_ENTERPRISE_ACCOUNT,
    })

    segment.track(DATA.SEGMENT.EVENTS.TALK_TO_SALES)

    window.open(DATA.ENTERPRISE_URL, '_blank')
  }

  const handleEmailChange = (e) => {
    setAccountExists(false)
    formik.setFieldValue(
      emailId,
      e.target.value
        .toLowerCase()
        .replace(/^\s+|\s+$/, '')
        .replace(/\s+/g, '')
    )
  }

  const tickUI = (index) => {
    if (!passwordChanged && !submitClicked) {
      return {
        item: <MiddleDot size={1.25} />,
        success: null,
      }
    }

    if (validSteps[index]) {
      return {
        item: <TickSuccess color={theme.palette.success.main} size={1.5} />,
        success: true,
      }
    }
    if (submitClicked) {
      return {
        item: <TickFail color={theme.palette.error.main} />,
        success: false,
      }
    }

    return {
      item: (
        <MiddleDot size={1.25} sx={{ opacity: passwordChanged ? 1 : 0.65 }} />
      ),
      success: null,
    }
  }

  const updateValidationUI = (index, label) => {
    const result = tickUI(index)

    return (
      <>
        <ListItemIcon
          sx={{
            '&.MuiListItemIcon-root svg': { height: '0.7em', width: ' 0.9em' },
            mr: '0 !important',
          }}
        >
          {result.item}
        </ListItemIcon>
        {/* eslint-disable-next-line no-nested-ternary */}
        <Typography
          sx={{
            color:
              // eslint-disable-next-line no-nested-ternary
              result.success === true
                ? theme.palette.success.main
                : result.success === false
                ? theme.palette.error.main
                : null,
            mr: 2,
            opacity: passwordChanged ? 1 : 0.65,
          }}
          variant="body0"
        >
          {label}
        </Typography>
      </>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (formik.errors[emailId] || formik.errors[passwordId]) {
      segment.track(DATA.SEGMENT.EVENTS.INVALID_CREDENTIALS)
    }

    if (formik.errors[emailId]) {
      segment.track(DATA.SEGMENT.EVENTS.INVALID_EMAIL)
    }

    if (formik.errors[passwordId]) {
      segment.track(DATA.SEGMENT.EVENTS.INVALID_PASSWORD)
    }

    formik.submitForm(e)
  }

  let focusTmr

  const handleBlur = () => {
    focusTmr = setTimeout(() => setIsKeyboardOpen(false))
  }

  const handleFocus = () => {
    clearTimeout(focusTmr)
    if (isDevice) {
      setIsKeyboardOpen(true)
    }
  }

  return (
    <Layout
      // backCallback={backMobileApp}
      backCallback={() => navigate(ROUTE_HOME)}
      header={{ ...DATA.header, backUrl: isWv ? true : null }}
    >
      <StepWrapper
        content={DATA.content}
        controls={[
          <LoadingButton
            color="orange"
            fullWidth
            loading={loading}
            onClick={(e) => {
              setSubmitClicked(true)
              handleSubmit(e)
            }}
            sx={{
              '&.Mui-disabled': {
                '&.MuiLoadingButton-loading': {
                  backgroundColor: loading && theme.backgrounds[10],
                },
              },
            }}
            variant="primary-form"
          >
            {!loading ? DIALOGS.continueBtn : DIALOGS.procesingBtn}
          </LoadingButton>,
          !isKeyboardOpen ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={0.5}
              sx={{
                mb: 2,
                mt: 1,
              }}
            >
              {DATA.ENTRERPRISE_TEXT_1}
              <Button onClick={handleClickLinkExternal} type="link">
                {DATA.ENTRERPRISE_TEXT_2}
              </Button>
            </Stack>
          ) : null,
        ]}
        eventCleverTap={DATA.CLEVERTAP.PAGE}
      >
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <FormText
              autoFocus
              disabled={OBAssistedEmail}
              error={
                (formik.touched[emailId] && formik.errors[emailId]) ||
                accountExists
              }
              helperText={
                accountExists && (
                  <>
                    {DATA.ERROR_MESSAGE.MAIL.EXISTS.TEXT1}
                    <Link
                      href={getLoginLink()}
                      onClick={handleClickLogin}
                      sx={{
                        color: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        textDecoration: 'underline',
                      }}
                    >
                      {DATA.ERROR_MESSAGE.MAIL.EXISTS.TEXT2}
                    </Link>
                    .
                  </>
                )
              }
              id={emailId}
              label={DATA.PLACEHOLDER.MAIL}
              onBlur={handleBlur}
              onChange={(e) => handleEmailChange(e)}
              onFocus={handleFocus}
              placeholder={DATA.PLACEHOLDER.MAIL}
              value={formik.values[emailId]}
            />
            <>
              <Box>
                <FormPassword
                  error={
                    formik.touched[passwordId] && formik.errors[passwordId]
                  }
                  id={passwordId}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setPasswordChanged(true)
                    formik.handleChange(e)
                  }}
                  onFocus={handleFocus}
                  placeholder={DATA.PLACEHOLDER.PASSWORD}
                  value={formik.values[passwordId]}
                />
              </Box>
              <List
                aria-label="Requisites"
                sx={{
                  '& .MuiListItem-root': {
                    alignItems: 'center',
                    display: 'flex',
                    pb: 0.5,
                    pt: 0,
                    width: 'auto',
                  },
                  '&.MuiList-root': { mt: 0 },
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                variant="compact"
              >
                {RULES.map((req, index) => (
                  <ListItem key={req.title}>
                    {updateValidationUI(index, req.title)}
                  </ListItem>
                ))}
              </List>
            </>
            <Tac
              isInvalid={submitClicked && !tacAccepted}
              onChange={(e) => {
                setTacAccepted(!e.target.value)
              }}
              sx={{
                '& .rendered-link': { textTransform: 'lowercase' },
                transform: `translateX(-${theme.space[3]}px)`,
              }}
            />
          </Stack>
        </form>
        <SubmitError
          onClose={() => setOpenSnackbar(false)}
          open={openSnackbar}
        />
      </StepWrapper>
    </Layout>
  )
}

export default AccountPage
