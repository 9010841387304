/* eslint-disable no-underscore-dangle */
import {
  PHONE_FIRST_GROUP_SPLIT_INDEX,
  PHONE_MAX_LENGTH,
  PHONE_REGEX,
  PHONE_SECOND_GROUP_SPLIT_INDEX,
} from 'constants/phone-requisites'

import React from 'react'

/**
 * @brief returns a random string up to a max length
 * @param {number} maxLength - max length of returned string (default = 6/max value = 12)
 */
export const randomString = (maxLength = 6) =>
  Math.random()
    .toString(36)
    .replace(/[^a-z0-9]+/g, '')
    .substr(0, maxLength)

/**
 * @brief returns a string without special characters or converted to ASCII eqiuvalent
 * @param {string} str - string to sanitize
 */
export const sanitizeString = (str) =>
  str
    .trim()
    .replace('á', 'a')
    .replace('é', 'e')
    .replace('í', 'i')
    .replace('ó', 'o')
    .replace('ú', 'u')
    .replace('ñ', 'n')
    // eslint-disable-next-line no-control-regex
    .replace(/[^\x00-\x7F]+/g, '')

// based on: https://stackoverflow.com/questions/55988065/react-how-to-format-phone-number-as-user-types#answer-55989037
// https://codesandbox.io/s/happy-snyder-bjlmt
/**
 * @brief returns a mexican-formated phone number
 * @param {string} val - value to format
 */
export const formatPhoneNumber = (val) => {
  if (!val) {
    return val
  }

  const phoneNumber = val.replace(PHONE_REGEX, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < PHONE_FIRST_GROUP_SPLIT_INDEX + 1) {
    return phoneNumber
  }

  if (phoneNumberLength < PHONE_SECOND_GROUP_SPLIT_INDEX + 1) {
    return `(${phoneNumber.slice(
      0,
      PHONE_FIRST_GROUP_SPLIT_INDEX
    )}) ${phoneNumber.slice(PHONE_FIRST_GROUP_SPLIT_INDEX)}`
  }

  return `(${phoneNumber.slice(
    0,
    PHONE_FIRST_GROUP_SPLIT_INDEX
  )}) ${phoneNumber.slice(
    PHONE_FIRST_GROUP_SPLIT_INDEX,
    PHONE_SECOND_GROUP_SPLIT_INDEX
  )} ${phoneNumber.slice(PHONE_SECOND_GROUP_SPLIT_INDEX, PHONE_MAX_LENGTH)}`
}

/**
 * @brief returns a React Component based on privided name and applies props
 * @param {string} name - exact name of component
 * @param {object} props - props to send to created component
 */
export const createReactComponentByName = (name, props) =>
  React.createElement(name, { ...props })

/**
 * @brief allow scape when is debug
 * @param {string} email
 * @returns string
 */
export const getEmailDebug = (email) => {
  const [body, domain] = email.split('@')

  const posIndex = body.search(/\+/)

  if (posIndex < 0) {
    return email
  }

  return `${body.slice(0, posIndex)}@${domain}`
}

/**
 * @brief check if the email is valid
 * @param {string} value
 * @param {bool} isDebug
 * @returns {boolean}
 */
export const validateEmail = (value) => {
  const filter =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,7}(?:\.[a-z]{2})?)$/i

  return filter.test(value)
}

/**
 * @brief find and return long name of entry ina a google API `address_components` array
 * @param {array} items - Google's address_components object
 * @param {string} entry - entry to find
 * @return {string || undefined}
 * @example
  const data = [
    {
        "long_name": "1234",
        "short_name": "1234",
        "types": [
            "street_number"
        ]
    },
    {
        "long_name": "Avenida Rivadavia",
        "short_name": "Av. Rivadavia",
        "types": [
            "route"
        ]
    },
  ]

  getGoogleAddressValue(data, 'street_number') => '1234'
  getGoogleAddressValue(data, 'route') => 'Avenida Rivadavia'
 */
export const getGoogleAddressValue = (items, entry) =>
  items.find((item) => item.types.find((component) => component === entry))
    ?.long_name

export const getCurrentDomain = () => {
  const { protocol, hostname: domain } = window.location

  return `${protocol}//${domain}`
}

export const debugPrint = (isDebug, label, printData) => {
  if (isDebug) {
    console.log('--------DEBUG-------')
    if (label) console.log(`::${label}::`)
    if (printData) console.log(printData)
    console.log('--------/DEBUG-------')
  }
}

export const isDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

export const isAndroid = /Android/i.test(navigator.userAgent)

export const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)

export const getOSName = () => {
  if (isIOS) {
    return 'ios'
  }

  if (isAndroid) {
    return 'android'
  }

  return 'web'
}

export const getLoginLink = () => {
  const paths = {
    dev: 'https://dev-mf-merchant-dashboard-container.payclip.com/md/users/sign_in',
    prod: 'https://dashboard.clip.mx/md/users/sign_in',
    stage: 'https://stage-dashboard.payclip.com/md/users/sign_in',
  }

  const environment = process.env.REACT_APP_ENV || 'prod'
  return paths[environment] || paths.prod
}

/**
 * @brief returns if we are under a webview (embbeded) / view loaded in a device APP withou checking userAgent, not under the user's device navigator (p.e. chrome / safari, etc)
 * @return {bool}
 * */
export const isWebView = () =>
  !!(
    window?.WebView ||
    window?.ReactNativeWebView ||
    window?.cordova ||
    window?._cordovaNative ||
    window?.webkit?.messageHandlers ||
    window?.Android ||
    window?.navigator.userAgent.includes('wv')
  )
