import { useEffect } from 'react'
import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  ShopStore,
  Lock,
  MoneyBag,
  // SendAndReceive,
  // Bolt,
  // ExposureZero,
  // SecurePayments,
} from 'components/Icons'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import { ROUTE_SYNC, ROUTE_OPTIONAL_KYC } from 'constants/routes'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import { useUserFormContext, actions } from 'components/Form/store/user'
import {
  startNativeBridge,
  recordAppsFlyerEvent,
} from 'helpers/callNativeBridge'
import DATA from 'constants/pages/verifyIdentity.json'
import SITE from 'constants/site.json'
import navigateIfNotLoggedIn from 'helpers/navigateIfNotLoggedIn'

const VerifyIdentityPage = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { userDispatch, userState } = useUserFormContext()
  const navigate = useNavigate()

  const content = DATA.content_1_2.optionalKYC
  const list = DATA.list_1_2.optionalKYC
  const buttonContinueText = DATA.buttonContinueText_1_2

  useEffect(() => {
    navigateIfNotLoggedIn(navigate)
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE[0],
    })
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const onContinue = (e) => {
    userDispatch({ kycInProcess: true, type: actions.UPDATE_KYC_STATUS })

    startNativeBridge(e)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.launch,
    })

    segment.track(DATA.SEGMENT.EVENTS.GO_TO_KYC)

    const eventName = 'pmkt_clip-app_kyc_selected'
    const eventParams = {
      merchant_id: userState?.mid ?? localStorage.getItem(SITE.LS_MID),
    }

    recordAppsFlyerEvent(eventName, JSON.stringify(eventParams))

    setTimeout(() => {
      navigate(ROUTE_SYNC)
    }, 3000)
  }

  const handleKYCManually = () => {
    segment.track(DATA.SEGMENT.EVENTS.GO_TO_SKIP_KYC)
    navigate(ROUTE_OPTIONAL_KYC)
  }

  const renderIcon = (i) => {
    switch (i) {
      case 0:
        // return <Bolt />
        return <Lock color={theme.palette.primary.main} />
      case 1:
        return <MoneyBag color={theme.palette.primary.main} />
      case 2:
        return <ShopStore color={theme.palette.primary.main} />
      // case 3:
      //   return <SecurePayments />
      default:
        return null
    }
  }

  return (
    <Layout header={DATA.header} skipCallback={handleKYCManually}>
      <StepWrapper
        content={content}
        controls={[
          <Button
            color="orange"
            onClick={onContinue}
            size="full-width"
            sx={{
              height: '48px',
              width: '100%',
            }}
            variant="primary-form"
          >
            {buttonContinueText}
          </Button>,
        ]}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        imageSize={{ height: 96, width: 96 }}
        sx={{ mt: 2 }}
        titleStyles={{ marginX: '16px', mt: 3 }}
      >
        <Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 4, mt: -1 }}
          >
            <Chip
              label={DATA.content_1_2.optionalKYC.free_label}
              sx={{
                background: theme.palette.success.main,
                color: theme.palette.success.light,
                fontSize: '12px',
                fontWeight: theme.fontWeight.semibold,
                height: 'auto',
              }}
            />
          </Stack>

          {list.title && (
            <Typography fontWeight={theme.fontWeight.semibold} variant="body1">
              {list.title}
            </Typography>
          )}
          <List
            aria-label="Requisites"
            sx={{ padding: 1.5, pb: 2, pt: 0 }}
            variant="compact"
          >
            {list.items.map((item, idx) => (
              <ListItem
                key={item}
                sx={{
                  borderBottom:
                    idx !== list.items.length - 1 &&
                    '1px solid #F2F4F7 !important',
                  pb: '16px !important',
                  pt: '16px !important',
                }}
              >
                <ListItemIcon
                  sx={{
                    alignItems: 'center',
                    borderRadius: '100px',
                    display: 'flex',
                    height: '1rem',
                    justifyContent: 'center',
                    padding: 2,
                    width: '1rem',
                  }}
                >
                  {renderIcon(idx)}
                </ListItemIcon>
                <Typography
                  dangerouslySetInnerHTML={{ __html: item }}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    fontWeight: theme.fontWeight.semibold,
                  }}
                  variant="body2"
                />
              </ListItem>
            ))}
          </List>

          <Typography align="center" variant="body0">
            {DATA.content_1_2.optionalKYC.disclaimer}
          </Typography>
        </Stack>
      </StepWrapper>
    </Layout>
  )
}

export default VerifyIdentityPage
