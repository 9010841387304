import KYC from 'constants/kyc'

const COMPANY_NAMES = [
  'S.N.C.',
  'S.N.C',
  'snc',
  'S.A.S.',
  'S.A.S',
  'sas',
  'S. en C.S',
  's en cs',
  'S. de R.L',
  's de rl',
  'S.C.A.',
  'S.C.A',
  'sca',
  'S.C.',
  'S.C',
  'sc',
  'S.A.',
  'S.A',
  'sa',
  'S. A. de C. V.',
  'sa de cv',
  'S.A.B.',
  'S.A.B',
  'sab',
  'S.A.P.I.',
  'S.A.P.I',
  'sapi',
  's.as',
  's.nc',
]

const hasCompanyName = (inputValue) =>
  COMPANY_NAMES.some((value) => {
    // Escape dots in company names (e.g. 'S.N.C.' should match 'S.N.C.' not 'S', 'N', 'C')
    const escapedValue = value.replace(/\./g, '\\.')
    const regex = new RegExp(`(?:^|\\W)${escapedValue}(?:\\W|$)`, 'i')

    return regex.test(inputValue)
  })

const isKycAccepted = (status) => {
  const subSteps = status?.userData?.subSteps
  const kycSubstep = subSteps?.find((e) => e.stepId === 'kycCompleted')

  if (!subSteps) {
    console.error('No subSteps key found!')
  }

  return kycSubstep?.status === KYC.ACCEPTED
}

export { hasCompanyName }
export default isKycAccepted
