/* eslint-disable import/no-extraneous-dependencies */
import Stack from '@mui/material/Stack'
import StepWrapper from 'components/StepWrapper'
import { getLoginLink, isDevice } from 'utils'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import Image from 'components/Image'
import DATA from 'constants/pages/home.json'
import { ROUTE_ACCOUNT_CREATION } from 'constants/routes'

const HomePageWeb = () => {
  const navigate = useNavigate()

  return (
    <StepWrapper
      content={{
        ...DATA.web,
        title: isDevice ? DATA.web.titleMobile : DATA.web.titleDesktop,
      }}
      controls={[
        isDevice ? (
          <Button
            color="orange"
            href={DATA.web.store_link}
            size="full-width"
            sx={{
              height: '48px',
              width: '100%',
            }}
            variant="primary-form"
          >
            {DATA.web.downloadAppBtn}
          </Button>
        ) : null,
        null,
        <Button
          color={isDevice ? 'secondary' : 'orange'}
          onClick={() => {
            navigate(ROUTE_ACCOUNT_CREATION)
          }}
          size="full-width"
          sx={{
            height: '48px',
            width: '100%',
          }}
          variant="primary-form"
        >
          {DATA.web.continueBtn}
        </Button>,
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
          sx={{
            mb: 2,
            mt: 1,
          }}
        >
          {DATA.web.loginTxt}
          <Button
            href={getLoginLink()}
            type="link"
          >
            {DATA.web.loginTxt2}
          </Button>
        </Stack>,
      ]}
    >
      <Stack alignItems="space-between">
        <Typography variant="body2">
          {isDevice ? DATA.web.mobileContent : DATA.web.desktopContent}
        </Typography>
        {!isDevice ? (
          <Stack alignItems="center" sx={{ mt: 5 }}>
            <Image alt="icon" height="230" src={DATA.web.qrSrc} width="230" />
          </Stack>
        ) : null}
      </Stack>
    </StepWrapper>
  )
}

export default HomePageWeb
